<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <v-row>
      <v-col class="container ma-16">
        <v-row class="header">
          <img
            src="@/assets/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/admin-config')"
          >
          <span class="robot-setting-title">&nbsp;&nbsp;&nbsp;옵션 관리</span>
          <v-spacer />
        </v-row>
        <v-col class="content-area">
          <h3 class="robot-option-title">Ver.{{this.$store.state.version}}</h3>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> Model Flag </p>
            <input v-model="model" placeholder="m 또는 h 또는 s" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('model')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 0번 도킹만 표출 </p>
            <input v-model="only_home_docking" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('only_home_docking')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 조그모드시 GPIO 버튼활성 </p>
            <input v-model="use_gpio" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_gpio')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 태스크시 위치도착 반경 </p>
            <input v-model="ros_collision_circle_radius" placeholder="0.1 ~ 2 (단위는 미터고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('ros_collision_circle_radius')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 조그모드시 전진 속도 </p>
            <input v-model="jog_front_speed" placeholder="0.1 ~ 2 (단위는 m/s고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('jog_front_speed')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 조그모드시 후진 속도 </p>
            <input v-model="jog_back_speed" placeholder="0.1 ~ 2 (단위는 m/s고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('jog_back_speed')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 조그모드시 회전 속도 </p>
            <input v-model="jog_rotation_speed" placeholder="0.1 ~ 2 (단위는 m/s고 숫자만 입력)" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('jog_rotation_speed')" > 저장 </v-btn> </p>
          </v-col>
          <v-col class="12 pa-0">
            <p class="default-robot-option-text"> 실험적인 기능 </p>
            <input v-model="use_preview" placeholder="0 또는 1" type="text" class="input-setting">
            <p class="default-robot-option-text"> <v-btn class="btn" color="#00BFB4" dark @click="saveData('use_preview')" > 저장 </v-btn> </p>
          </v-col>

          <v-btn
            class="btn mt-9"
            color="#00BFB4"
            dark
            @click="update"
          >
            소프트웨어 업데이트 (인터넷연결 필수)
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'AdminSetting',
  data() {
    return {
      model: 'm',
      only_home_docking: 0,
      use_gpio: 0,
      ros_collision_circle_radius: 1,
      jog_front_speed: 0.4,
      jog_back_speed: 0.2,
      jog_rotation_speed: 0.3,
      use_preview: 0,
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData(){
      const res = await this.$axios.get('/info');
      if (res.status !== 200) return;

      const options= res.data;
      for(const option_name in options){
        switch (option_name){
          case'model': this.model= options[option_name]; break;
          case'only_home_docking': this.only_home_docking= options[option_name]; break;
          case'use_gpio': this.use_gpio= options[option_name]; break;
          case'ros_collision_circle_radius': this.ros_collision_circle_radius= options[option_name]; break;
          case'jog_front_speed': this.jog_front_speed= options[option_name]; break;
          case'jog_back_speed': this.jog_back_speed= options[option_name]; break;
          case'jog_rotation_speed': this.jog_rotation_speed= options[option_name]; break;
          case'use_preview': this.use_preview= options[option_name]; break;
        }
      }
    },
    async saveData(name){
      let value= '';
      switch (name){
        case'model': value= this.model; break;
        case'only_home_docking': value= this.only_home_docking; break;
        case'use_gpio': value= this.use_gpio; break;
        case'ros_collision_circle_radius': value= this.ros_collision_circle_radius; break;
        case'jog_front_speed': value= this.jog_front_speed; break;
        case'jog_back_speed': value= this.jog_back_speed; break;
        case'jog_rotation_speed': value= this.jog_rotation_speed; break;
        case'use_preview': value= this.use_preview; break;
      }
      const res = await this.$axios.put('/option', {
        option_name: name,
        option_value: value,
      });
      if (res.status === 200) await this.loadData();
    },
    async update(){
      await this.$axios.put('/update');
      await this.$router.replace('/');
    },
  }
}
</script>

<style lang="scss" scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
}

.container {
  height: 100vh;
}

.robot-setting-title {
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 80px;
}

.content-area {
  height: 60vh;
  text-align: center;
  overflow: auto;
}

.robot-option-title {
  padding-top: 30px;
  font-size: 2rem;
  color: white;
}

.input-setting {
  width: 30%;
  height: 80px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-setting:focus {
  outline: solid;
  outline-color: #9aa0ae;
}

.textarea {
  width: 70%;
  height: 180px;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 1rem;
  color: white;
}

.textarea:focus {
  outline: solid;
  outline-color: #9aa0ae;
}

.default-robot-option-text {
  padding-left: 30px;
  display: -webkit-inline-box;
  margin: 0;
  width: 30%;
  font-size: 1.5rem;
  color: white;
}

.default-robot-option-text > span {
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .robot-setting-title {
    font-size: 20px !important;
  }

  .input-setting {
    width: 30%;
    height: 30px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 2rem;
    color: white;
  }

  .textarea {
    width: 70%;
    height: 120px;
    padding: 20px;
    border: 2px solid #545861;
    background-color: black;
    font-size: 1rem;
    color: white;
  }

  .default-robot-option-text {
    padding-left: 30px;
    display: -webkit-inline-box;
    margin: 0;
    width: 30%;
    font-size: 1.0rem;
    color: white;
  }

  .default-robot-option-text > span {
    font-size: 0.7rem;
  }
}
</style>
